import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import './AppToolbar.scss';
import { useUserContext } from '../service/UserContext';
import BroadcastMessage from '../widget/BroadcastMessage';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGear } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

type AppTab = 'myTrips' | 'featuredTrips' | 'outline' | 'admin' | 'cityGuides';
interface AppToolbarProps {
}

export function AppToolbar(props: AppToolbarProps) {
  const [admin, setAdmin] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserContext();

  useEffect(() => {
    user.fetchUser().then(a => setAdmin(a?.admin));
  }, []);

  function login() {
    if(!user.isSignedIn()) {
      const originalUri = location.pathname + (location.search ?? '');
      const state = { originalUri };
      navigate(`/login`, { state });
    }
    else {
      user.logout().then(a => navigate('/'));
    }
  }

  const hidden = location.pathname === '/create';
  const guide = location.pathname.startsWith('/city-guide') || location.pathname.startsWith('/attraction-guide');
  return (
    <div>
      <div className={`app-toolbar ${hidden ? 'hidden' : ''}`}>
        <div className='title-div'>
          { /* <img src="/images/hat.svg" width={55} height={35} /> */}
          <NavLink to="/" className='bar-title'>Explore. Plan. Your Perfect Trip</NavLink>
          <div className="beta-label">Beta</div>
        </div>
        <NavLink to="/featured-trips" replace className='bar-item'>
          Be Inspired
        </NavLink>
        <NavLink to="/create" className='bar-item'>
          <FontAwesomeIcon icon={faUserGear}/>
          Explore & Plan
        </NavLink>
        <NavLink to="/mytrips" className='bar-item'>
          My Itineraries
        </NavLink>
        <NavLink to="/city-guides" className={`bar-item ${guide ? 'active' : ''}`}>
          City Guides
        </NavLink>
        {admin ?
          <NavLink to="/admin" className='bar-item'>
            Admin
          </NavLink> : null
        }
        <div className='right-icon-container'>
          <Tooltip title='About/Contact'>
            <Link to="/info/about" className='bar-item'>
              <InfoIcon />
            </Link>
          </Tooltip>
          <Tooltip title={user.isSignedIn() ? 'Sign Out' : 'Sign In'}>
            <a onClick={login} className='bar-item'>{user.isSignedIn() ? <ExitToAppIcon /> : <AccountCircleIcon />}</a>
          </Tooltip>
        </div>
      </div>
      <div><BroadcastMessage /></div>
    </div>
  );
}