import { Button, Card, CardContent, CardHeader, List, ListItem, Typography } from "@mui/material";
import "./LoginComponent.scss";

interface LoginComponentProps {
  cancel?: () => void;
  returnUrl?: string;
  title?: string;
  className?: string;
}

export function LoginComponent({ cancel, returnUrl, title, className }: LoginComponentProps) {
  const param = returnUrl ? `?returnTo=${encodeURIComponent(returnUrl)}` : '';
  const handleGoogle = () => window.location.href = `/auth/google${param}`;
  const handleFacebook = () => window.location.href = `/auth/facebook${param}`;
  return <Card className={`login-component-container ${className}`}>
    {!!title ?
      <CardHeader title={<Typography variant="h6">Getting Started</Typography>} /> : null
    }
    <CardContent>
      <div className='login-label'>
        Sign in to build your itinerary...
      </div>
      <List>
        <ListItem className='item-button'>
          <Button variant='contained' color="primary" onClick={handleGoogle}>
            Sign in with Google
          </Button>
        </ListItem>
        <ListItem className='item-button'>
          <Button variant='contained' color="primary" onClick={handleFacebook}>
            Sign in with Facebook
          </Button>
        </ListItem>
      </List>
      {!!cancel ?
        <div className='action-pane'>
          <Button onClick={cancel} variant='outlined'>Cancel</Button>
        </div> : null
      }
    </CardContent>
  </Card>;
}
