import React, { useState, MouseEvent, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Link, Typography } from '@mui/material';
import { Menu as MenuIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { getAppName } from 'trip-util';
import { useUserContext } from '../service/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileAppBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For hamburger menu
  const [anchorElRight, setAnchorElRight] = useState<null | HTMLElement>(null); // For icon menu dropdown
  const [menuOpen, setMenuOpen] = useState(false);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserContext();

  useEffect(() => {
    user.fetchUser().then(a => a);
  }, []);

  function login() {
    if(!user.isSignedIn()) {
      const originalUri = location.pathname + (location.search ?? '');
      const state = { originalUri };
      navigate(`/login`, { state });
    }
    else {
      user.logout().then(a => navigate('/'));
    }

    handleRightMenuClose();
  }

  const handleMenuOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = (): void => {
    setMenuOpen(false);
  };

  const handleRightMenuOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElRight(event.currentTarget);
    setRightMenuOpen(true);
  };

  const handleRightMenuClose = (): void => {
    setRightMenuOpen(false);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        {/* Left: Hamburger Menu */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
          sx={{ display: { xs: 'block', md: 'none' } }} // Only on mobile
        >
          <MenuIcon />
        </IconButton>

        {/* Center: Title or Logo (Optional) */}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" noWrap>
            Map&Hat | {getAppName()}
          </Typography>
        </Box>

        {/* Right: Icon Menu Dropdown */}
        <IconButton
          color="inherit"
          onClick={handleRightMenuOpen}
          sx={{ display: { xs: 'block', md: 'none' } }} // Only on mobile
        >
          <MoreVertIcon />
        </IconButton>

        {/* Hamburger Menu (on mobile) */}
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}><Link href="/featured-trips">Home</Link></MenuItem>
          <MenuItem onClick={handleMenuClose}><Link href="/mytrips">My Trips</Link></MenuItem>
          <MenuItem onClick={handleMenuClose}><Link href="/city-guides">City Guides</Link></MenuItem>
        </Menu>

        {/* Icon Menu Dropdown (on mobile) */}
        <Menu
          anchorEl={anchorElRight}
          open={rightMenuOpen}
          onClose={handleRightMenuClose}
        >
          <MenuItem onClick={handleRightMenuClose}><Link href="/info/about">About</Link></MenuItem>
          <MenuItem onClick={login}>Login</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBar;
