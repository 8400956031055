import { TripDay } from '@shared/types/itinerary';

// get the city part of the location
export function getLocationCity(location?: string): string | undefined {
  return location?.split(',')?.[0]?.trim();;
}

// get the country part of the location
export function getLocationCountry(location?: string): string | undefined {
  const parts = location?.split(',');

  if(parts?.length > 1) {
    return parts[parts.length - 1].trim();
  }

  return undefined;
}

// get the locations of all attractions in the day, as a string.
export function getLocationsString(tripDay: TripDay, full = false): string {
  return tripDay.locations.map(loc => full ? loc.location : getLocationCity(loc.location)).join('/').trim();
}

// get the day title
export function getTripDayTitle(day: TripDay): string {
  const locations = getLocationsString(day);
  const overnight = getLocationCity(day.overnightCity);

  if(locations === overnight || !overnight) {
    return locations;
  }

  return `${locations} (${overnight})`;
}

// get the image place paramter to be used for /image?place=... url paramter.
export function getImagePlace(place: string, location?: string): string {
  return location ? `${place},${location}` : place;
}

// check if two place names refer to the same attraction.
export function isSamePlace(p1: string, p2: string): boolean {
  // name that is a substring of another name is considered a duplicate.
  return p1.includes(p2) || p2.includes(p1);
}