import React from 'react';
import './LoadingScreen.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

interface LoadingScreenProps {
  children?: React.ReactNode
  height?: string;
  className?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ children, height, className }) => {
  return (
    <div className={`loading-screen ${height ? '' : 'full-screen'} ${className}`}
      style={{ height: height }}>
      <FontAwesomeIcon icon={faGear} size='5x' className='spinner' />
      {children}
    </div>
  );
};

export default LoadingScreen;
