import { getLocationCity, getLocationCountry } from "./shareUtil";

export function normalizeCountry(country: string): string {
  if(!country || country === 'NA') {
    return country;
  }

  const paren = country.indexOf('(');
  country = paren > 0 ? country.substring(0, paren).trim() : country;
  const slash = country.indexOf('/');
  country = slash > 0 ? country.substring(0, slash).trim() : country;

  if(country.startsWith('England')) {
    return 'UK';
  }
  else if(country.includes('Micronesia')) {
    return 'Micronesia';
  }
  else if(country.startsWith('Laos')) {
    return 'Laos';
  }
  else if(country == 'United Kingdom') {
    return 'UK';
  }
  else if(country.startsWith('St. Kitts')) {
    return 'St. Kitts';
  }
  else if(country === 'UAE') {
    return 'United Arab Emirates';
  }
  else if(country === 'USA') {
    return 'United States';
  }

  return country;
}

export function normalizeLocation(location: string): string {
  if(location.endsWith(', Caribbean')) {
    return location.substring(0, location.lastIndexOf(','));
  }

  if(location.includes(',')) {
    const comma = location.lastIndexOf(',');
    const country = normalizeCountry(location.substring(comma + 1).trim());
    const others = location.substring(0, comma).trim();
    return `${others}, ${country}`;
  }

  // city state, e.g. Singapore
  return `${location}, ${location}`;
}