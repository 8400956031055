import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

export const initGA = () => {
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID!;
  ReactGA.initialize(TRACKING_ID);
};

export const trackPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return null;
};