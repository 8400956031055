import axios, { AxiosError } from "axios";
import { convertDates } from "trip-util";

export function initAxios(setError: React.Dispatch<React.SetStateAction<string>>) {
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => {
      response.data = convertDates(response.data);
      return response;
    },
    (error: AxiosError) => {
      if(error.response?.status === 401 && !(error.request as XMLHttpRequest)?.responseURL.endsWith("/checkAuth")) {
        console.log("failed 401:", error);
        setError("Session expired, re-login...");
      }

      return Promise.reject(error);
    }
  );
}

