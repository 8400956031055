import React from 'react';
import "./Login.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginComponent } from '../component/home/LoginComponent';

interface LoginProps {
  returnTo?: string;
}

const Login: React.FC = ({ returnTo }: LoginProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const returnUrl = location.state.returnTo || returnTo;
  const cancel = () => navigate('/');

  return (
    <div className={`login-container`}>
      <LoginComponent cancel={cancel} returnUrl={returnUrl} title='Getting Started'/>
    </div>
  );
};

export default Login;