import React, { useState, MouseEvent, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Link, Typography } from '@mui/material';
import { Menu as MenuIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { getAppName } from 'trip-util';
import { useUserContext } from '../service/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const MobileAppBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For hamburger menu
  const [anchorElRight, setAnchorElRight] = useState<null | HTMLElement>(null); // For icon menu dropdown
  const [menuOpen, setMenuOpen] = useState(false);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserContext();

  useEffect(() => {
    user.fetchUser().then(a => a);
  }, []);

  function login() {
    if(!user.isSignedIn()) {
      const originalUri = location.pathname + (location.search ?? '');
      const state = { originalUri };
      navigate(`/login`, { state });
    }
    else {
      user.logout().then(a => navigate('/'));
    }

    handleRightMenuClose();
  }

  const handleMenuOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = (): void => {
    setMenuOpen(false);
  };

  const handleRightMenuOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElRight(event.currentTarget);
    setRightMenuOpen(true);
  };

  const handleRightMenuClose = (): void => {
    setRightMenuOpen(false);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen} >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" noWrap>
            Map&Hat | {getAppName()}
          </Typography>
        </Box>
        <IconButton color="inherit" onClick={handleRightMenuOpen}>
          <MoreVertIcon />
        </IconButton>

        <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>
            <Link href="/featured-trips">Featured Itineraries</Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link href="/mytrips">My Trips</Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link href="/city-guides">City Guides</Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link href="/editor-info">
              Itinerary Builder &nbsp;
              <FontAwesomeIcon icon={faInfoCircle} />
            </Link>
          </MenuItem>
        </Menu>

        <Menu anchorEl={anchorElRight} open={rightMenuOpen} onClose={handleRightMenuClose}>
          <MenuItem onClick={handleRightMenuClose}><Link href="/info/about">About</Link></MenuItem>
          <MenuItem onClick={login}>{user.isSignedIn() ? 'Logout' : 'Login'}</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBar;
