import React from 'react';
import { Card, CardContent, CardMedia, Button, Typography, Container, Grid } from '@mui/material';
import './CreatePage.scss';

const CreatePage: React.FC = () => {
  return (
    <div className='create-page-container'>
      <header className='create-page-header'>
        <Typography variant="h4" component="h1">
          AI Itinerary Builder
        </Typography>
        <Typography variant="h6" paragraph>
          Plan your perfect trip with ease using our AI powered interactive itinerary builder.
        </Typography>
      </header>

      <Container maxWidth="lg" className='first-card'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  Create Your Perfect Itinerary
                </Typography>
                <Typography variant="body1">
                  Our AI-powered itinerary builder makes planning your trip a breeze. Simply chat with our chatbot to
                  create a personalized itinerary that suits your interests. You can visualize your trip on an interactive
                  map, modify plans in real time, and add detailed reservations and flight information on a calendar.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  Why Desktop?
                </Typography>
                <Typography variant="body1">
                  The interactive itinerary builder is optimized for a large screen for the best experience. Enjoy full
                  functionality, including detailed planning, map visualization, and calendar scheduling, which are best
                  viewed on a desktop/laptop computer.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Screenshot Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  Screenshot of the Interactive Itinerary Builder
                </Typography>
                <Typography variant="body1">
                  Visualize your trip in a map and modify plans interactively with chatbot.
                </Typography>
              </CardContent>
              <CardMedia component="img" image="/images/tripchat.png" alt="Interactive Itinerary Builder Screenshot"/>
            </Card>
          </Grid>

          {/* CTA Card */}
          <Grid item xs={12}>
            <Card className='cta-card'>
              <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  Access the Feature on Desktop
                </Typography>
                <Typography variant="body1" paragraph>
                  To get the most out of the itinerary building process, please access this feature on a
                  desktop/laptop computer.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CreatePage;
